/**
 * Start Reset File
 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
img,
ins,
kbd,
q,
s,
samp,
del,
dfn,
em,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
button,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
input,
select,
option,
textarea {
  margin: 0;
  padding: .25em;
  border: 1px solid black;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
i,
cite,
em,
var,
address,
dfn {
  font-style: italic;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none inside;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html,
body {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
dt,
dd,
label {
  margin: 0;
  padding: .5em .25em 0 .25em;
  line-height: 1.25em;
}
h2,
h3 {
  font-weight: bold;
}
input,
textarea,
select,
option {
  margin: .5em .25em 0 .25em;
  padding: 0;
  line-height: 1.25em;
}
body,
h4,
h5,
h6,
p,
li,
dt,
dd,
label,
input,
textarea,
select,
option {
  font-size: 15pt;
}
h3 {
  font-size: 1.1em;
}
h2 {
  font-size: 1.25em;
}
h1 {
  font-size: 1.5em;
}
/** Responsive Mixins **/
/** browser Mixins **/
@page {
  size: A4;
  margin: 50px;
}
@media print {
  html body a {
    text-decoration: none;
    color: #333333;
  }
  html body iframe {
    display: none;
  }
  html body header #appLogo {
    text-indent: 0 !important;
  }
  html body .pageBanner {
    overflow: hidden;
    height: 150px !important;
    margin: 0 !important;
  }
  html body .pageBanner .pageBannerBG {
    position: absolute;
    bottom: 0;
    right: 0;
    overflow: visible;
    text-indent: 0;
  }
  html body .pageBanner .pageBannerBG img {
    display: block;
    width: 100%;
  }
  html body .pageBanner #watermark {
    background: transparent !important;
  }
  html body .pageBanner #printWatermark {
    display: block !important;
    margin-left: -220px;
    top: 18px;
    width: 204px;
    height: auto;
  }
  html body .sectionSet,
  html body .subPod {
    display: block !important;
    margin: 0 !important;
  }
  html body section {
    display: block !important;
    margin: 12pt 3pt !important;
    padding: 0 !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    -o-box-shadow: none !important;
    box-shadow: none !important;
  }
  html body section.resumeOverview,
  html body section.resumeSkills,
  html body section.resumeJobs,
  html body section.resumeReferences {
    page-break-after: always;
  }
  html body section.resumeSkills::before,
  html body section.resumeReferences::before,
  html body section.resumeJobs::before {
    display: block;
    content: " ";
    position: relative;
    height: 60px;
  }
  html body section .jobsList,
  html body section .quoteSet {
    display: block;
  }
  html body section .jobsList .quote,
  html body section .quoteSet .quote,
  html body section .jobsList .jobPod,
  html body section .quoteSet .jobPod {
    display: block;
    page-break-inside: avoid;
    position: relative;
    padding: 60px 0 0;
    margin-right: 1em;
    margin-left: 1em;
  }
  html body section .jobsList .quote:first-child,
  html body section .quoteSet .quote:first-child,
  html body section .jobsList .jobPod:first-child,
  html body section .quoteSet .jobPod:first-child {
    padding-top: 1em;
  }
}
html {
  margin: 0;
  padding: 0;
}
html body {
  margin: 0;
  padding: 50px 0 2.5em;
  background: #ffffff;
  color: #333333;
  font-family: "Utsaah";
}
html body .error {
  color: #990000 !important;
}
html body .success {
  color: #009900 !important;
}
html body .note {
  color: #666666 !important;
  font-style: italic;
}
html body .actionBar {
  position: relative;
  display: flex;
  justify-content: flex-end;
  gap: .25em;
  padding: .25em 0;
}
html body .buttonList {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 0;
}
html body .buttonList .item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
html body .buttonList .item:first-child .button {
  border-width: 1px;
}
html body .buttonList .item .button {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  border-width: 1px 1px 1px 0;
}
html body dl {
  font-size: .75em;
}
html body dl dt {
  float: left;
  font-weight: bold;
  font-size: 1em;
}
html body dl dd {
  clear: right;
  font-size: 1em;
}
html body .button,
html body button {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  background: linear-gradient(to bottom, #ffffff, #7d6158);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  font-size: 16px;
  border: 1px solid #cccccc;
  border-color: #333333;
  padding: .25em .5em;
  line-height: 1.7em;
  color: #333333;
  text-decoration: none;
}
html body .button:hover,
html body button:hover {
  background: linear-gradient(to bottom, #7d6158, #ffffff);
}
html body .button:disabled,
html body button:disabled,
html body .button.disabled,
html body button.disabled {
  cursor: default;
  background: #ffffff;
  color: #666666;
}
html body header {
  border: 2px solid #7d6158;
  background: #ffffff;
  border-width: 0 0 2px 0;
  position: fixed;
  z-index: 101;
  top: 0;
  right: 0;
  left: 0;
  height: 55px;
}
html body header #appLogo {
  position: absolute;
  top: 8px;
  left: 9px;
  height: 36px;
  width: 167px;
  background: transparent url(images/logo.png) top left no-repeat;
  overflow: hidden;
  text-indent: -100em;
}
html body header #mainMenu #mainMenuList {
  position: absolute;
  bottom: 13px;
  right: .5em;
}
html body header #mainMenu #mainMenuList .navItem {
  padding: 0;
}
html body header #mainMenu #mainMenuList .navItem.hasSubMenu.showing > .navLink {
  background: #7d6158;
  color: #ffffff;
}
html body header #mainMenu #mainMenuList .navItem .navLink {
  text-decoration: none;
  padding: .25em;
}
html body header #mainMenu #mainMenuList .navItem .navLink:hover,
html body header #mainMenu #mainMenuList .navItem .navLink:active {
  text-decoration: underline;
}
html body .pageBanner {
  position: relative;
  height: 300px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background: #ffffff url('images/home.png') no-repeat bottom right;
  border-bottom: 1px solid #7d6158;
}
@media only screen and (max-width: 790px) {
  html body .pageBanner {
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -ms-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    background-color: #f9efef;
    height: 130px;
  }
}
html body .pageBanner .pageBannerBG {
  position: absolute;
  overflow: hidden;
  text-indent: -100em;
}
html body .pageBanner #watermark,
html body .pageBanner #printWatermark {
  position: absolute;
  left: 50%;
}
html body .pageBanner #watermark {
  background: transparent url(images/watermark.png) top left no-repeat;
  overflow: hidden;
  text-indent: -100em;
  top: 60px;
  margin-left: -150px;
  height: 185px;
  width: 300px;
}
@media only screen and (max-width: 790px) {
  html body .pageBanner #watermark {
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -ms-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    margin-left: -75px;
    top: 30px;
    height: 92px;
    width: 150px;
  }
}
html body .pageBanner #printWatermark {
  display: none;
}
html body .pageBanner .globalMessaging {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
html body .pageBanner .globalMessaging div {
  padding: .25em 1em;
  border: 1px solid #cccccc;
  background: #ffffff;
}
html body .loading {
  display: block;
  min-width: 10em;
  color: #666666;
}
html body table {
  border: 1px solid #cccccc;
}
html body table tr {
  margin: 3em;
  /*
            &:hover {
                background: #eee;
            }
            */
}
html body table tr:nth-child(even) {
  background-color: #f2f2f2;
}
html body table th,
html body table td {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  padding: .25em;
  border: 1px solid #cccccc;
  vertical-align: middle;
  font-size: 16px;
  white-space: nowrap;
}
html body table th.actionCell,
html body table td.actionCell {
  padding: 0;
  vertical-align: top;
}
html body .sectionSet {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: flex-start;
  background: linear-gradient(to bottom right, #f3f3f3, #f8f8f8, #f3f3f3, #f8f8f8, #f3f3f3);
  background-size: 6px 6px;
  margin-top: -2em;
  padding-bottom: 1em;
}
@media only screen and (min-width: 790px) and (max-width: 1030px), only screen and (max-width: 790px) {
  html body .sectionSet {
    flex-direction: column;
  }
}
@media only screen and (max-width: 790px) {
  html body .sectionSet {
    margin-top: 0;
  }
}
html body .sectionSet dl.sectionCallout {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  float: right;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  max-width: 10em;
  margin: 1em 0 .25em .75em;
  padding: 0.25em;
  background: #f3f3f3;
  font-size: .75em;
  line-height: 1em;
}
html body .sectionSet dl.sectionCallout dt,
html body .sectionSet dl.sectionCallout dd {
  margin: .25em 0 .25em .25em;
  padding: 0;
  font-size: 1em;
  line-height: 1em;
}
html body .sectionSet dl.sectionCallout dt {
  font-weight: bold;
}
html body .sectionSet.storeSet {
  background: #ffffff;
}
html body .sectionSet.storeSet .sectionPod {
  margin: 1em;
}
html body .sectionSet > .subPod:nth-child(1),
html body .sectionSet > .sectionPod:nth-child(1) {
  flex-basis: 30%;
}
html body .sectionSet > .subPod:nth-child(2),
html body .sectionSet > .sectionPod:nth-child(2) {
  flex-basis: 40%;
}
html body .sectionSet > .subPod:nth-child(3),
html body .sectionSet > .sectionPod:nth-child(3) {
  flex-basis: 30%;
}
html body .sectionSet .subPod {
  display: flex;
  flex-direction: column;
}
html body .sectionSet .subPod .sectionPod {
  margin: 1em .5em 0;
}
@media only screen and (max-width: 790px) {
  html body .sectionSet .subPod .sectionPod {
    margin: .25em 0;
  }
}
@media only screen and (max-width: 790px) {
  html body .sectionSet .subPod .sectionPod:first-child {
    margin: 0;
  }
}
@media only screen and (min-width: 790px) and (max-width: 1030px) {
  html body .sectionSet .subPod:last-child .sectionPod:first-child {
    margin: 1em;
  }
}
html body .sectionSet .sectionPod {
  /*flex-basis: 30%;*/
  z-index: 1;
  margin: 1em .5em;
  padding: .75em 1em 1em;
  background: #ffffff;
  -webkit-border-radius: 1em;
  -moz-border-radius: 1em;
  -ms-border-radius: 1em;
  -o-border-radius: 1em;
  border-radius: 1em;
  -webkit-box-shadow: 0 0 5px #777777 ;
  -moz-box-shadow: 0 0 5px #777777 ;
  -ms-box-shadow: 0 0 5px #777777 ;
  -o-box-shadow: 0 0 5px #777777 ;
  box-shadow: 0 0 5px #777777 ;
}
@media only screen and (min-width: 790px) and (max-width: 1030px) {
  html body .sectionSet .sectionPod {
    margin: 1em;
  }
}
@media only screen and (max-width: 790px) {
  html body .sectionSet .sectionPod,
  html body .sectionSet .sectionPod:last-of-type {
    margin: .25em 0;
    padding: .25em;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
  }
}
html body .sectionSet .sectionPod .title {
  font-size: 1.5em;
  font-weight: bold;
  line-height: 1em;
  padding: 0;
  color: #993333;
}
@media only screen and (max-width: 790px) {
  html body .sectionSet .sectionPod .title {
    text-align: center;
  }
}
html body footer {
  border: 2px solid #7d6158;
  background: #ffffff;
  z-index: 101;
  border-width: 1px 0 0 0;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2.5em;
}
@media only screen and (max-width: 790px) {
  html body footer {
    position: relative;
    z-index: 0;
    margin-top: .5em;
  }
  html body footer .cite {
    display: block;
    font-size: .75em;
    padding-left: .25em;
    width: auto;
    text-align: right;
    line-height: 1.5em;
  }
  html body footer .cite em {
    display: block;
  }
}
@media only screen and (max-width: 420px) {
  html body footer {
    height: auto;
  }
  html body footer .cite {
    position: relative !important;
    text-align: left !important;
    right: 0 !important;
    top: 0 !important;
  }
}
html body footer .cite {
  position: absolute;
  right: 1em;
  top: .25em;
  font-size: .75em;
}
html body footer .cite .sentence {
  margin-right: .5em;
}
html body footer .cite .sentence:last-child {
  margin-right: 0;
}
html body footer .cite dl {
  margin: 0;
  padding: .25em 0 0;
  display: flex;
}
html body footer .cite dl dt,
html body footer .cite dl dd {
  margin: 0;
  padding: 0 .25em 0 0;
  font-size: 1em;
}
html body footer .cite dl dd:first-of-type::after {
  content: ",";
  margin-right: .5em;
}
html body footer .cite a {
  font-style: normal;
}
html body footer .socialNav #connectionWidgets li {
  padding: .4em 0 0 .4em;
}
html body footer .socialNav .socialLink {
  position: relative;
  display: inline-block;
  height: 32px;
  width: 32px;
  overflow: hidden;
  text-indent: -1000px;
  background: transparent url(images/icons/social32.png) top left no-repeat;
}
html body footer .socialNav .socialLink#link_linkedIn {
  background-position: 0 -64px;
}
html body footer .socialNav .socialLink#link_facebook {
  background-position: 0 0px;
}
html body footer .socialNav .socialLink#link_twitter {
  background-position: 0 -128px;
}
html body footer .socialNav .socialLink#link_youtube {
  background-position: 0 -192px;
}
html body footer .socialNav .socialLink#link_patreon {
  background-position: 0 -256px;
}
html body footer .socialNav .socialLink#link_instagram {
  background-position: 0 -320px;
}
.inlineList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: flex-start;
}
@media only screen and (max-width: 420px) {
  .inlineList {
    display: block;
  }
  .inlineList .listBlock {
    display: block;
    margin-bottom: 1em;
  }
}
.inlineList .listBlock .listTitle {
  font-style: italic;
}
.inlineList .listBlock .listList {
  padding-left: .5em;
  list-style-type: disc;
}
.inlineList .listBlock .listList li {
  padding: 0;
  font-size: .8em;
}
.quoteSet .quote:nth-child(even) {
  background: #f3f3f3;
}
.quote {
  text-align: justify;
  margin: 0;
  padding: .5em 1em;
}
.quote .quoteSource {
  display: block;
  font-size: .8em;
  text-align: right;
}
.quote .quoteSource .name {
  color: #993333;
  font-style: normal;
  font-weight: bold;
}
.jobsList {
  display: flex;
  flex-direction: column;
}
.jobsList :nth-child(even).jobPod {
  background: #f3f3f3;
}
.jobsList .jobPod {
  margin: 0;
  padding: .5em 1em;
}
.jobsList .jobPod .jobTitle,
.jobsList .jobPod .jobCompany,
.jobsList .jobPod .jobDates {
  padding-top: 0;
  padding-left: 0;
}
.jobsList .jobPod .jobTitle {
  font-weight: bold;
}
.jobsList .jobPod .jobCompany {
  font-size: .75em;
  color: #666666;
}
.jobsList .jobPod .jobDates {
  font-size: .75em;
}
.editor {
  display: block;
}
.editor .docBody {
  display: block;
  margin: 0;
  width: 100%;
  height: 15em;
}
.editor .editorLabel {
  display: block;
}
